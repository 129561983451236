import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import RightArrow from "../../images/video-play.svg"
import HomeIcon from "../../images/home.svg"
import * as $ from 'jquery'

export default class PlayerControlExample extends Component {

  componentDidMount() {

    // video
    $("#video-active").on(
      "timeupdate",
      function(event){
        var time = this.currentTime;
        onTrackedVideoFrame(time);
      }
    );

    $('video').on('play',function(){
      $(this)[0].webkitEnterFullscreen();
    });

    $('video').on('ended',function(){
      $(this)[0].webkitExitFullscreen();
    });

    // track video time
    function onTrackedVideoFrame(currentTime){

      var video = document.getElementById("video-active");

      if(currentTime > 14.75 && currentTime < 15) {
        video.pause();
      } else if (currentTime > 17.75 && currentTime < 18) {
        video.pause();
      } else if (currentTime > 22 && currentTime < 22.25) {
        video.pause();
      } else if (currentTime > 28.75 && currentTime < 29) {
        video.pause();
      } else if (currentTime > 36.25 && currentTime < 36.5) {
        video.pause();
      } else if (currentTime > 38.25 && currentTime < 38.5) {
        video.pause();
      } else if (currentTime > 40 && currentTime < 40.25) {
        video.pause();
      } else if (currentTime > 43.75 && currentTime < 44) {
        video.pause();
      } else if (currentTime > 45.25 && currentTime < 45.5) {
        video.pause();
      } else if (currentTime > 48.75 && currentTime < 49) {
        video.pause();
      } else if (currentTime > 71.75 && currentTime < 72) {
        video.pause();
      }
    }
  }

  render() {

    return (
      <Layout>

      <Link to="/" className="gohome">
        <HomeIcon />
      </Link>

        <section className="video-page text-center">
          <div className="container">

            <h1>Base Orion Unit Plus Options</h1>

            <div className="video-holder">
              <video
                id="video-active"
                className="video-active" preload="auto" controls>
                <source src="https://player.vimeo.com/external/408750843.hd.mp4?s=0543ff90c830cc43fe5bef66f9af5a19d233f055&profile_id=175" type='video/mp4'/>
              </video>
            </div>

            <h3>Next: Base Orion Unit Plus Options With Recovery RO</h3>
            <Link to="/videos/video-3" className="btn plus-before">Watch Video</Link>
          </div>
        </section>
      </Layout>
    );
  }
}
